import { useQuery } from "@tanstack/react-query";
import { useApiQuery } from "../lib/api";
import { Test } from "@gradience/api-types";
import { useParams } from "@tanstack/react-router";

export const useLatestTest = () => {
  const tests = useApiQuery("/tests", {});

  const latestTestId = tests.data?.data.reduce((a, b) =>
    new Date(a.reservationDateStart) > new Date(b.reservationDateStart) ? a : b
  ).id;

  return useQuery<Test | undefined>(["latest-test", latestTestId], async () => {
    return tests.data?.data.find((test) => test.id === latestTestId);
  });
};

export const useSelectedTest = () => {
  const params = useParams({
    strict: false,
  });

  const tests = useApiQuery("/tests", {});

  const selectedTestSlug =
    params["test-slug"] ??
    tests.data?.data.reduce((a, b) =>
      new Date(a.reservationDateStart) > new Date(b.reservationDateStart)
        ? a
        : b
    ).yearIdentifier;

  return useQuery<Test | undefined>(
    ["selected-test", selectedTestSlug],
    async () => {
      return tests.data?.data.find(
        (test) => test.yearIdentifier === selectedTestSlug
      );
    },
    { enabled: selectedTestSlug !== undefined && tests.data !== undefined }
  );
};
