import axios, { AxiosError, isAxiosError } from "axios";
import {
  QueryKey,
  UseQueryOptions,
  useQuery,
  useMutation,
  UseMutationOptions,
  MutationKey,
} from "@tanstack/react-query";
import { ErrorResponseBody, Routes } from "@gradience/api-types";
import config from "./config";
import Bugsnag from "@bugsnag/js";

const { VITE_API_URL, VITE_OFFLINE_DEVELOPMENT_MODE, VITE_TENANT_ID } = config;

const api = axios.create({
  baseURL: VITE_API_URL,
  headers: {
    "x-gradience-tenant-id": VITE_TENANT_ID,
  },
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx will cause this function to trigger
    return response;
  },
  (error) => {
    Bugsnag.notify(error);
    return Promise.reject(error);
  }
);

export default api;

const addParamsToPath = <
  TMethod extends keyof Routes[TPath],
  TPath extends keyof Routes,
  TParams extends ExtractParams<Routes[TPath][TMethod]>,
>(
  path: TPath,
  params?: TParams
) => {
  let pathWithParams: string = path;
  if (params) {
    for (const [key, value] of Object.entries(params)) {
      pathWithParams = pathWithParams.replace(
        `:${key}`,
        // Idk why I have to cast -\_(o_o)_/-
        encodeURIComponent(value as string)
      );
    }
  }
  return pathWithParams;
};

export const LOGGED_IN_USER_KEY = ["me"] as const;
export const queryKeys: {
  [TPath in keyof Routes]: QueryKey & MutationKey;
} = {
  "/school": ["school"],
  "/users": ["users"],
  "/users/:id": ["users"],
  "/setup-intents": ["setup-intents"],
  "/auth/login": LOGGED_IN_USER_KEY,
  "/auth/register": LOGGED_IN_USER_KEY,
  "/auth/verify-email": LOGGED_IN_USER_KEY,
  "/auth/password-reset-request": ["password-reset-request"],
  "/auth/password-reset": LOGGED_IN_USER_KEY,
  "/me": LOGGED_IN_USER_KEY,
  "/manifest": ["manifest"],
  "/auth/verify-email-request": ["verify-email-request"],
  "/tests/:slug": ["tests"],
  "/school-list": ["school-list"],
  "/auth/impersonation-token": ["impersonation-token"],
  "/groups": ["groups"],
  "/groups/:id": ["groups"],
  "/student-lists": ["students"],
  "/students": ["students"],
  "/curricula": ["curricula"],
  "/chapters": ["chapters"],
  "/concept-groups": ["concept-groups"],
  "/number-of-questions": ["number-of-questions"],
  "/auth/admin/login": LOGGED_IN_USER_KEY,
  "/auth/admin/password-reset-request": ["password-reset-request"],
  "/auth/admin/password-reset": LOGGED_IN_USER_KEY,
  "/questions": ["questions"],
  "/questions/:id": ["questions"],
  "/question-pools": ["question-pools"],
  "/concepts": ["concepts"],
  "/chapters/:id": ["chapters"],
  "/curricula/:id": ["curricula"],
  "/chapters/order": ["chapters"],
  "/concepts/:id": ["concepts"],
  "/concept-groups/:id": ["concept-groups"],
  "/supplemental-concept-groups": ["supplemental-concept-groups"],
  "/supplemental-concept-groups/:id": ["supplemental-concept-groups"],
  "/supplemental-concepts/:id": ["supplemental-concept-groups"],
  "/supplemental-concepts": ["supplemental-concept-groups"],
  "/tests": ["tests"],
  "/answers": ["answers"],
  "/test-scans": ["test-scans"],
  "/test-scan-pages": ["test-scan-pages"],
  "/test-dates-overrides": ["test-dates-overrides"],
  "/test-dates-overrides/:id": ["test-dates-overrides"],
  "/grade-tests": ["grade-tests"],
  "/grading-progress": ["grading-progress"],
  "/test-scan-pages/:id/fiducial-annotations": ["fiducial-annotations"],
  "/test-scan-pages/:id": ["test-scan-pages"],
  "/grading-issues": ["grading-issues"],
  "/grading-issues/:id": ["grading-issues"],
  "/test-scan-pages/:id/answer-circle-annotations": [
    "answer-circle-annotations",
  ],
  "/test-scan-pages/:id/partial-answer-circle-annotations": [
    "answer-circle-annotations",
  ],
  "/grading-issues/:id/resolution": ["grading-issue-resolution"],
  "/score-snapshots": ["score-snapshots"],
  "/school/payment-method": ["school-payment-method"],
  "/todo-items": ["todo-items"],
};

export type ResponseForPath<
  TPath extends keyof Routes,
  TMethod extends keyof Routes[TPath],
> = Routes[TPath][TMethod] extends {
  response: infer TResponse;
}
  ? TResponse
  : never;

export type UseMutationOptionsForEndpoint<
  TPath extends keyof Routes,
  TMethod extends keyof Routes[TPath],
> = UseMutationOptions<
  ResponseForPath<TPath, TMethod>,
  unknown,
  ExtractBody<Routes[TPath][TMethod]>
>;

type ExtractBody<T> = T extends { body: infer TBody } ? TBody : {};

export const getToken = () => localStorage.getItem("token");

export const getApi = async <
  TPath extends keyof Routes,
  TMethod extends "get" extends keyof Routes[TPath] ? "get" : never,
  TParams extends ExtractParams<Routes[TPath][TMethod]>,
  TQuery extends ExtractQuery<Routes[TPath][TMethod]>,
>(
  path: TPath,
  params: TParams,
  query?: TQuery
) => {
  const token = getToken();

  const res = await api.get<ResponseForPath<TPath, TMethod>>(
    addParamsToPath<TMethod, TPath, TParams>(path, params),
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
      params: query,
    }
  );

  return res.data;
};

export type ExtractParams<T> = T extends { params: infer TParams }
  ? TParams
  : {};

export type ExtractQuery<T> = T extends { query: infer TQuery } ? TQuery : {};

export const isApiError = (
  error: unknown
): error is AxiosError<ErrorResponseBody> => {
  return isAxiosError(error) && error.response?.data?.errors;
};

export const useApiQuery = <
  TPath extends keyof Routes,
  TMethod extends "get" extends keyof Routes[TPath] ? "get" : never,
  TParams extends ExtractParams<Routes[TPath][TMethod]>,
>(
  path: TPath,
  params: TParams,
  options?: UseQueryOptions<ResponseForPath<TPath, TMethod>, unknown>,
  query?: ExtractQuery<Routes[TPath][TMethod]>
) =>
  useQuery<ResponseForPath<TPath, TMethod>, unknown>(
    [...queryKeys[path], params, query],
    async () => {
      return await getApi(path, params, query);
    },
    {
      ...(VITE_OFFLINE_DEVELOPMENT_MODE
        ? { networkMode: options?.networkMode ?? "always" }
        : {}),
      ...options,
    }
  );

type RequestForPath<
  TPath extends keyof Routes,
  TMethod extends keyof Routes[TPath],
> = Routes[TPath][TMethod] extends { body: infer TRequest } ? TRequest : {};

const putApi = async <
  TPath extends keyof Routes,
  TMethod extends "put" extends keyof Routes[TPath] ? "put" : never,
  TParams extends ExtractParams<Routes[TPath][TMethod]>,
>(
  path: TPath,
  body: RequestForPath<TPath, TMethod>,
  params?: TParams
) => {
  const token = getToken();
  return api.put<ResponseForPath<TPath, TMethod>>(
    addParamsToPath(path, params),
    body,
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    }
  );
  // return res.data;
};

export const useApiPut = <
  TPath extends keyof Routes,
  TMethod extends "put" extends keyof Routes[TPath] ? "put" : never,
  TParams extends ExtractParams<Routes[TPath][TMethod]>,
>(
  path: TPath,
  params?: TParams,
  options?: UseMutationOptions<
    ResponseForPath<TPath, TMethod>,
    unknown,
    RequestForPath<TPath, TMethod>,
    unknown
  >
) =>
  useMutation<
    ResponseForPath<TPath, TMethod>,
    unknown,
    RequestForPath<TPath, TMethod>,
    unknown
  >(
    [...queryKeys[path], params],
    async (body) => {
      const res = await putApi(path, body, params);
      return res.data;
    },
    {
      ...(VITE_OFFLINE_DEVELOPMENT_MODE
        ? { networkMode: options?.networkMode ?? "always" }
        : {}),
      ...options,
    }
  );

export const postApi = async <
  TPath extends keyof Routes,
  TMethod extends "post" extends keyof Routes[TPath] ? "post" : never,
>(
  path: TPath,
  body: RequestForPath<TPath, TMethod>
) => {
  const token = getToken();
  const res = await api.post<ResponseForPath<TPath, TMethod>>(path, body, {
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
  return res.data;
};

export const useApiPost = <
  TPath extends keyof Routes,
  TMethod extends "post" extends keyof Routes[TPath] ? "post" : never,
>(
  path: TPath,
  options?: UseMutationOptions<
    ResponseForPath<TPath, TMethod>,
    unknown,
    RequestForPath<TPath, TMethod>
  >
) =>
  useMutation<
    ResponseForPath<TPath, TMethod>,
    unknown,
    RequestForPath<TPath, TMethod>,
    unknown
  >(
    queryKeys[path],
    async (body) => {
      return await postApi(path, body);
    },
    {
      ...(VITE_OFFLINE_DEVELOPMENT_MODE
        ? { networkMode: options?.networkMode ?? "always" }
        : {}),
      ...options,
    }
  );

const deleteApi = async <
  TPath extends keyof Routes,
  TMethod extends "delete" extends keyof Routes[TPath] ? "delete" : never,
  TParams extends ExtractParams<Routes[TPath][TMethod]>,
>(
  path: TPath,
  body: RequestForPath<TPath, TMethod>,
  params?: TParams
) => {
  const token = getToken();
  const res = await api.delete<ResponseForPath<TPath, TMethod>>(
    addParamsToPath(path, params),
    {
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
      data: body,
    }
  );
  return res.data;
};

export const useApiDelete = <
  TPath extends keyof Routes,
  TMethod extends "delete" extends keyof Routes[TPath] ? "delete" : never,
  TParams extends ExtractParams<Routes[TPath][TMethod]>,
>(
  path: TPath,
  params?: TParams,
  options?: UseMutationOptions<
    ResponseForPath<TPath, TMethod>,
    unknown,
    RequestForPath<TPath, TMethod>,
    unknown
  >
) =>
  useMutation<
    ResponseForPath<TPath, TMethod>,
    unknown,
    RequestForPath<TPath, TMethod>,
    unknown
  >(
    [...queryKeys[path], params],
    async (body) => {
      return await deleteApi(path, body, params);
    },
    {
      ...(VITE_OFFLINE_DEVELOPMENT_MODE
        ? { networkMode: options?.networkMode ?? "always" }
        : {}),
      ...options,
    }
  );
