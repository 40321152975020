import {
  createRootRoute,
  Outlet,
  useMatches,
  useNavigate,
  useSearch,
} from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import { useLoggedInUser } from "../lib/auth";
import NotFoundPage from "./not-found-page";
import CreateGroupModal from "./modals/create-group";
import { useSelectedTest } from "../domain/use-latest-test";
import SupportModal from "./modals/support";

const unauthenticatedRoutes = [
  "/reset-password",
  "/request-password-reset",
  "/sign-up",
  "/log-in",
  "/",
];

const modals = ["create-group", "support"] as const;
type Modal = (typeof modals)[number];
type RootSearch = {
  modal?: Modal | undefined;
  test?: string | undefined;
};

export const Route = createRootRoute({
  component: Root,
  notFoundComponent: NotFoundPage,
  validateSearch: (search): RootSearch => {
    const validatedSearch: RootSearch = {};

    if (modals.find((v) => v === search.modal)) {
      validatedSearch.modal = search.modal as Modal;
    }

    if (search.test && typeof search.test === "string") {
      validatedSearch.test = search.test;
    }

    return validatedSearch;
  },
});

function Root() {
  const navigate = useNavigate();
  const routes = useMatches();

  const user = useLoggedInUser();
  const selectedTest = useSelectedTest();

  const path = useMemo(() => {
    return routes[1]?.pathname;
  }, [routes]);

  const search = useSearch({
    from: "__root__",
  });

  useEffect(() => {
    if (user.status !== "success") {
      return;
    }

    if (user.data) {
      if (user.data.emailVerified) {
        if (path === "/verify-email") {
          navigate({
            to: "/$test-slug/home",
            params: {
              "test-slug": selectedTest.data?.yearIdentifier ?? "",
            },
          });
        }
        if (unauthenticatedRoutes.includes(path)) {
          navigate({
            to: "/$test-slug/home",
            params: {
              "test-slug": selectedTest.data?.yearIdentifier ?? "2024-25",
            },
          });
        }
      } else {
        navigate({
          to: "/verify-email",
          search: {
            token: "",
          },
        });
      }
    } else {
      if (!unauthenticatedRoutes.includes(path)) {
        navigate({
          to: "/",
        });
      }
    }
  }, [
    user.data,
    path,
    navigate,
    user.isFetched,
    user.status,
    selectedTest.data?.yearIdentifier,
  ]);

  return (
    <div
      style={{
        overflow: "auto",
        maxHeight: "100dvh",
      }}
    >
      <CreateGroupModal isOpen={search.modal === "create-group"} />
      <SupportModal isOpen={search.modal === "support"} />
      <Outlet />
    </div>
  );
}
