import { useForm } from "@tanstack/react-form";
import {
  Text,
  Modal,
  ModalButtonsRow,
  Button,
  Loader,
  useDesignTokens,
  ClickableText,
} from "@gradience/ui";
import { Column, Row } from "@gradience/ui";
import displayDate from "../lib/display-date";
import {
  isApiError,
  useApiDelete,
  useApiPost,
  useApiPut,
  useApiQuery,
} from "../lib/api";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { ComponentProps, useMemo, useState } from "react";
import { isAxiosError } from "axios";
import { useSelectedTest } from "../domain/use-latest-test";
import Input, {
  InputOptions,
  InputType,
} from "@gradience/ui/dist/components/input";
import { CSSProperties } from "styled-components";

const GroupConfigurationModal = ({
  groupId,
  onClose,
  open,
}: {
  open: boolean;
  groupId: string;
  onClose: React.ComponentProps<typeof Modal>["close"];
}) => {
  const designTokens = useDesignTokens();
  const isCreate = groupId === "create";
  const [dateFieldError, setDateFieldError] = useState<string>();
  const [otherError, setOtherError] = useState<string>();
  const updateGroupMutation = useApiPut(
    "/groups/:id",
    {
      id: groupId ?? "",
    },
    {
      onError: (error) => {
        if (isApiError(error)) {
          const testDateError = error.response?.data.errors.find(
            (e) => e.path?.[0] === "testDate"
          );
          if (testDateError) {
            setDateFieldError(testDateError.message);
          }
        }
      },
    }
  );
  const createGroupMutation = useApiPost("/groups", {
    onError: (error) => {
      if (isApiError(error)) {
        const testDateError = error.response?.data.errors.find(
          (e) => e.path?.[0] === "testDate"
        );
        if (testDateError) {
          setDateFieldError(testDateError.message);
        } else {
          // if it's a 400 error, we can show it, otherwise generic contact support
          setOtherError(
            error.response?.status === 400
              ? error.response?.data.errors.map((e) => e.message).join(" ")
              : "An error occurred. Please contact support."
          );
        }
      }
    },
  });
  const deleteGroupMutation = useApiDelete("/groups/:id", {
    id: groupId ?? "",
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const group = useApiQuery(
    "/groups/:id",
    {
      id: groupId ?? "",
    },
    { enabled: !isCreate }
  );

  const form = useForm({
    defaultValues: useMemo(
      () => ({
        groupName: group.data?.name
          ? // Would be better to not set the group name on the backend, but this works.
            group.data.name === "Unnamed Group"
            ? ""
            : group.data.name
          : "",
        instructor: {
          firstName: group.data?.instructorFirstName ?? "",
          lastName: group.data?.instructorLastName ?? "",
          email: group.data?.instructorEmail ?? "",
        },
        testDate: group.data?.testDate
          ? new Date(group.data.testDate).toISOString().slice(0, 10)
          : "",
      }),
      [
        group.data?.instructorEmail,
        group.data?.instructorFirstName,
        group.data?.instructorLastName,
        group.data?.name,
        group.data?.testDate,
      ]
    ),
    onSubmit: async ({ value }) => {
      try {
        const res = await (
          isCreate ? createGroupMutation : updateGroupMutation
        ).mutateAsync({
          name: value.groupName,
          instructorEmail: value.instructor.email || "",
          instructorFirstName: value.instructor.firstName,
          instructorLastName: value.instructor.lastName,
          testDate: new Date(value.testDate).toISOString(),
          testId: test.data?.id ?? "",
          reviewedAt: undefined,
          shouldEmailInstructor: true,
        } as any);
        form.reset();
        setDateFieldError(undefined);
        setOtherError(undefined);
        queryClient.invalidateQueries(["groups"]);
        navigate({
          to: "/$test-slug/groups/$id",
          params: {
            "test-slug": test.data?.yearIdentifier ?? "",
            id: isCreate ? (res?.id ?? "") : groupId,
          },
        });
      } catch (e) {
        if (!isAxiosError(e)) {
          throw e;
        }
      }
    },
  });

  const FormField = ({
    name,
    label,
    placeholder,
    required,
    style,
    options,
    type,
    errorText,
    autocomplete,
    helpText,
    autofocus,
  }: {
    name: ComponentProps<(typeof form)["Field"]>["name"];
    label?: string;
    placeholder?: string;
    required?: boolean;
    style?: CSSProperties;
    options?: InputOptions;
    type?: InputType;
    errorText?: string;
    autocomplete?: string;
    helpText?: string;
    autofocus?: boolean;
  }) => {
    return (
      <form.Field
        name={name}
        children={({ name, state, handleBlur, handleChange }) => {
          return (
            <Input
              helpText={helpText}
              style={style}
              label={label}
              placeholder={placeholder}
              required={required}
              autocomplete={autocomplete}
              // @ts-ignore
              name={name}
              options={options}
              type={type}
              inputProps={{
                autoFocus: autofocus,
                value: state.value as string,
                onBlur: handleBlur,
                onChange: (e) => {
                  // This will work as long as we use all string values until I
                  // can fix this
                  // @ts-ignore
                  handleChange(e.target.value);
                },
              }}
              errorText={
                state.meta.errors.length
                  ? state.meta.errors.join(", ")
                  : errorText
              }
            />
          );
        }}
      />
    );
  };

  const test = useSelectedTest();

  return (
    <Modal open={open} close={onClose}>
      {group.isLoading && !isCreate ? (
        <Loader />
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 32,
            width: "100%",
          }}
        >
          <Row
            gap={8}
            style={{
              alignItems: "center",
            }}
          >
            <Text textStyle="headingMedium" style={{ flex: 1 }}>
              Group Information
            </Text>
            {!isCreate && (
              <ClickableText
                type="button"
                onClick={async () => {
                  if (
                    window.confirm(
                      "Are you sure you want to delete this group? This cannot be undone."
                    )
                  ) {
                    await deleteGroupMutation.mutateAsync({
                      id: groupId,
                    });

                    queryClient.invalidateQueries(["groups"]);
                    navigate({
                      to: "/$test-slug/home",
                      params: {
                        "test-slug": test.data?.yearIdentifier ?? "",
                      },
                    });
                  }
                }}
              >
                Delete Group
              </ClickableText>
            )}
          </Row>
          <FormField
            autofocus
            label="Group Name"
            name="groupName"
            required
            placeholder="Group Name"
            helpText="e.g. “6th Grade Latin,” “Honors Latin 3,” “Mr. Smith 8th Grade Section B” etc."
          />
          <Column gap={8}>
            <Text textStyle="strong">Instructor*</Text>
            <Row gap={8}>
              <FormField
                style={{ flex: 1 }}
                name="instructor.firstName"
                required
                placeholder="First Name"
              />
              <FormField
                style={{ flex: 1 }}
                name="instructor.lastName"
                required
                placeholder="Last Name"
              />
            </Row>
            <FormField
              name="instructor.email"
              placeholder="Email Address (optional)"
              type="email"
            />
          </Column>
          <Column gap={8}>
            <Text textStyle="strong">Test Date*</Text>
            <Text
              style={{
                color: designTokens.colors.text.subdued,
              }}
            >
              You may pick any date between{" "}
              {displayDate(test.data?.administrationDateStart)} and{" "}
              {displayDate(test.data?.administrationDateEnd)}. Tests will be
              available for you to print five days before the test date you
              select.
            </Text>
            <FormField
              name="testDate"
              required
              type="date"
              errorText={dateFieldError}
            />
          </Column>
          {otherError && (
            <Text
              style={{
                color: designTokens.colors.text.brand,
              }}
            >
              {otherError}
            </Text>
          )}
          <ModalButtonsRow>
            <Button
              text={isCreate ? "Create Group" : "Update Group"}
              type="submit"
              variant="primary"
              loading={form.state.isSubmitting}
            />
            <Button
              loading={form.state.isSubmitting}
              onPress={() => onClose()}
              text="Cancel"
            />
          </ModalButtonsRow>
        </form>
      )}
    </Modal>
  );
};

export default GroupConfigurationModal;
